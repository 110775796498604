
import Vue from "vue";
import Component from "vue-class-component";
import CategoryDetailsHeader from "./header/CategoryDetailsHeader.vue";
import GridFilter from "@/components/grids/criterionListById/filter/GridFilter.vue";
import { StoreActionTypes } from "@/store/types";
import GridComponent from "@/components/grids/criterionListById/list/GridComponent.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import CreateCriterionModalForm from "@/components/form/CreateCriterionModalForm.vue";
import { ActionTypes as CriterionsActionTypes } from "@/store/modules/criterions/actions/types";
import { CategoryModel } from "@/services/types";

const CategoryDetailsProps = Vue.extend({
  props: {
    groupId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
  },
});

@Component({
  components: {
    CategoryDetailsHeader,
    GridFilter,
    GridComponent,
    ActionButton,
    ModalComponent,
    CreateCriterionModalForm,
  },
})
export default class CategoryDetails extends CategoryDetailsProps {
  private componentClass = "CategoryDetails";

  mounted() {
    this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_SELECTED_CATEGORY_WITH_CRITERIONS_LIST,
      this.groupId
    );
  }

  getGridActionType(): CriterionsActionTypes {
    return CriterionsActionTypes.SET_CRITERIONS_LIST_BY_CATEGORY_ID;
  }

  get loadData(): boolean {
    return (
      (this.$store.getters.getCategoriesSelectedCategory as CategoryModel) ==
      null
    );
  }
}

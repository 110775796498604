
import { isUserCanManageChecklists } from "@/services/roles/helpers";

declare const window: any;
import Vue from "vue";
import Component from "vue-class-component";
import CheckListDetailsHeader from "./header/CheckListDetailsHeader.vue";
import { StoreActionTypes, StoreMutationTypes } from "@/store/types";
import {
  CheckListModel,
  CreateCriterionFullRequestModel,
  CreateCriterionRequestModel,
} from "@/services/types";
import GridFilter from "@/components/grids/criterionListById/filter/GridFilter.vue";
import GridComponent from "@/components/grids/criterionListById/list/GridComponent.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import { ActionTypes as CriterionsActionTypes } from "@/store/modules/criterions/actions/types";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import FormInput from "@/components/lib/input/FormInput.vue";
// import EditCheckListModalForm from '@/components/form/editCheckList/EditCheckListModalForm.vue'
import DraggableTable from "@/components/grids/checkListsList/list/DraggableTable.vue";
import EditCheckListModalForm from "@/components/form/editCheckList/EditCheckListModalForm.vue";
import CreateCriterionModalForm from "@/components/form/CreateCriterionModalForm.vue";
import CheckListAddToProject from "@/components/checkListAddToProject/CheckListAddToProject.vue";
import { set } from "vue/types/umd";

const CheckListDetailsProps = Vue.extend({
  props: {
    checkListId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
  },
});

@Component({
  methods: { isUserCanManageChecklists },
  components: {
    CheckListAddToProject,
    CheckListDetailsHeader,
    GridFilter,
    GridComponent,
    ActionButton,
    ModalComponent,
    EditCheckListModalForm,
    DraggableTable,
    FormInput,
    CreateCriterionModalForm,
  },
})
export default class CheckListDetails extends CheckListDetailsProps {
  private componentClass = "CheckListDetails";
  private showEditCheckListForm = false;
  private editCheckList = false;
  private checklistCopy = null;
  private modalShow = false;
  private showCreateNewCriterionForm = false;
  private selectedProjectList = [];
  private categoryName = "";
  //private checkListProjects = [];

  async mounted() {
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_FULL_LIST_GET,
      this.checkListId
    );
    //await this.$store.dispatch(StoreActionTypes.CHECKLISTS_FULL_LIST, this.checkListId)
    await this.$store.dispatch(StoreActionTypes.SET_PROJECTS_FULL_LIST);
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_SELECTED_CHECKLIST,
      this.checkListId
    );
    this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIMITS);
    //this.checkQueryParams()
  }

  get loadData(): boolean {
    return (
      (this.$store.getters.getCheckListsSelectedCheckList as CheckListModel) ==
      null
    );
  }

  public get nestedList() {
    return this.$store.getters.getCriterionsList;
  }

  public set nestedList(value) {
    this.$store.commit(StoreMutationTypes.SET_CRITERIONS_LIST, value);
  }

  get modalOkBtnDisabled() {
    return this.disabled;
  }

  get disabled() {
    return this.$store.getters.isCategoriesDataLoading;
  }

  get createNewCriterionFormDisabled() {
    return false;
  }

  get canCreateProject() {
    return this.nestedList.length > 0;
  }

  async updateCheckList() {
    //await this.$store.dispatch(StoreActionTypes.CHECKLISTS_FULL_LIST_GET, this.checkListId);
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_SELECTED_CHECKLIST,
      this.checkListId
    );
  }

  editList(value) {
    this.$store.commit(StoreMutationTypes.SET_CRITERIONS_LIST, value);
  }

  checkQueryParams() {
    if (this.$route.query.isNew && this.$route.query.isNew == "true") {
      this.handleEditCheckListBtnClick();
    }
  }

  getGridActionType(): CriterionsActionTypes {
    return CriterionsActionTypes.SET_CRITERIONS_LIST_BY_CHECKLIST_ID;
  }

  handleEditCheckListBtnClick() {
    if (!this.editCheckList) {
      this.checklistCopy = JSON.parse(JSON.stringify(this.nestedList));
      return (this.editCheckList = true);
    }

    this.$store.dispatch(StoreActionTypes.CHECKLISTS_FULL_LIST_SAVE, {
      list: this.nestedList,
      checkListId: this.checkListId,
    });

    this.editCheckList = false;
  }

  handleEditCheckListCancelBtnClick() {
    this.nestedList = this.checklistCopy;
    this.editCheckList = false;
  }

  get editCheckListFormDisabled() {
    return this.$store.getters.isCheckListsDataLoading;
  }

  handleEditCheckListFormBtnClick() {
    this.showEditCheckListForm = !this.showEditCheckListForm;
  }

  async handleEditCheckListModalOkBtnClick() {
    const ids: number[] = (
      this.$refs.editCheckListModalForm as EditCheckListModalForm
    ).getCriterionsList();
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_EDIT_CHECKLIST_CRITERIONS_LIST,
      { ids }
    );
    //await this.$store.dispatch(StoreActionTypes.CHECKLISTS_SELECTED_CHECKLIST, this.checkListId);
    this.handleEditCheckListFormBtnClick();
  }

  handleAddNewCriterionModalBtnClick() {
    this.showCreateNewCriterionForm = !this.showCreateNewCriterionForm;
    this.handleEditCheckListFormBtnClick();
  }

  handleCreateNewCategoryBtnClick() {
    this.modalShow = !this.modalShow;
  }

  async handleModalOkBtnClick() {
    await this.createCategory();
  }

  async createCategory() {
    if (this.categoryName.length < 5) {
      window.error(
        "Имя группы не может быть пустым или содержать менее 5 символов",
        "Ошибка создания группы"
      );
      return;
    }
    await this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_CATEGORY_CREATE,
      this.categoryName
    );
    const categoryId = this.$store.getters.getCategoriesSelectedCategory.id;
    //await this.updateCheckList();
    this.handleCreateNewCategoryBtnClick();
    this.categoryName = "";
    window.info(
      'И доступна в разделе "Добавить критерий"',
      "Группа успешно создана"
    );
  }

  categoryNameChange(text: string) {
    this.categoryName = text;
  }
  handleCreateNewCriterionBtnClick() {
    this.showCreateNewCriterionForm = !this.showCreateNewCriterionForm;
  }

  async handleCreateNewCriterionModalOkBtnClick() {
    const criterion = (
      this.$refs.createNewCriterionModalForm as CreateCriterionModalForm
    ).getCreateCriterionRequestModel();

    const optionsNotFilled = criterion.options.find(
      (v) => v.name.length === 0 || v.nominalWeight === null
    );
    if (
      criterion.name.length === 0 ||
      criterion.categoryId === 0 ||
      criterion.options.length === 0 ||
      optionsNotFilled
    ) {
      window.error(
        "Проверьте правильность заполненных полей",
        "Ошибка создания критерия"
      );
      return;
    }

    const model: CreateCriterionFullRequestModel = {
      criterion,
      list: this.nestedList,
    };

    await this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_CRITERION_CREATE,
      model
    );
    await this.updateCheckList();
    this.handleCreateNewCriterionBtnClick();
  }
}


import Vue from "vue";
import Component from "vue-class-component";
import {
  IconDefinition,
  faArrowLeft,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { CheckListModel } from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import ConfirmationComponent from "@/components/modal/ConfirmationComponent.vue";
import { isUserCanManageChecklists } from "@/services/roles/helpers";

const ICheckListDetailsHeaderProps = Vue.extend({
  props: {
    isEdit: {
      required: true,
      type: Boolean,
    },
    canCreateProject: {
      required: true,
      type: Boolean,
    },
  },
});

@Component({
  methods: { isUserCanManageChecklists },
  components: {
    FormInput,
    ActionButton,
    ModalComponent,
    ConfirmationComponent,
  },
  watch: {
    "$store.getters.getCategoriesSelectedCategory": "setEditedCategoryName",
  },
})
export default class CheckListDetailsHeader extends ICheckListDetailsHeaderProps {
  private componentClass = "CheckListDetailsHeader";
  private back: IconDefinition = faArrowLeft;
  private edit: IconDefinition = faPencilAlt;
  private showChangeCheckListNameForm = false;
  private editedCheckListName = "";
  private deleteCheckListConfirmationShow = false;

  mounted() {
    this.setEditedCategoryName();
  }

  get checkListName() {
    return (this.$store.getters
      .getCheckListsSelectedCheckList as CheckListModel)
      ? (this.$store.getters.getCheckListsSelectedCheckList as CheckListModel)
          .name
      : "";
  }

  get checkListId() {
    return (this.$store.getters
      .getCheckListsSelectedCheckList as CheckListModel)
      ? (this.$store.getters.getCheckListsSelectedCheckList as CheckListModel)
          .id
      : "";
  }

  get modalChangeCheckListNameFormDisabled() {
    return this.disabled || this.editedCheckListName.length < 3;
  }

  get disabled() {
    return this.$store.getters.isCheckListsDataLoading;
  }

  handleEditCheckListNameBtnClick() {
    this.showChangeCheckListNameForm = !this.showChangeCheckListNameForm;
  }

  checkListNameChange(text: string) {
    this.editedCheckListName = text;
  }

  async handleModalChangeCheckListNameFormOkBtnClick() {
    await this.editCategoryName();
    this.handleEditCheckListNameBtnClick();
  }

  async editCategoryName() {
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_CHECKLIST_CHANGE_NAME,
      this.editedCheckListName
    );
  }

  handleBackIconClick() {
    this.$store.dispatch(StoreActionTypes.CHECKLISTS_SELECTED_CHECKLIST_CLEAR);
    this.$router.push("/check-lists");
  }

  setEditedCategoryName() {
    this.editedCheckListName = (this.$store.getters
      .getCheckListsSelectedCheckList as CheckListModel)
      ? (this.$store.getters.getCheckListsSelectedCheckList as CheckListModel)
          .name
      : "";
  }

  handleDeleteCheckListBtnClick() {
    this.deleteCheckListConfirmationShow = true;
  }

  deleteCheckListCancel() {
    this.deleteCheckListConfirmationShow = false;
  }

  async deleteCheckList() {
    await this.$store.dispatch(StoreActionTypes.CHECKLISTS_CHECKLIST_DELETE, {
      ids: [
        (this.$store.getters.getCheckListsSelectedCheckList as CheckListModel)
          ? (
              this.$store.getters
                .getCheckListsSelectedCheckList as CheckListModel
            ).id
          : null,
      ],
    });

    this.deleteCheckListCancel();
    this.handleBackIconClick();
  }

  handleCreateProjectBtnClick() {
    this.$router.push({
      name: "projectCreateStepOne",
      query: { checkListId: `${this.checkListId}` },
    });
  }
}


import Vue from "vue";
import Component from "vue-class-component";
import GridFilter from "@/components/grids/callsList/filter/GridFilter.vue";
import GridFooter from "@/components/grids/main/gridFooter/GridFooter.vue";
import GridComponent from "@/components/grids/callsList/list/GridComponent.vue";
import CallGridColumnsSelect from "@/components/select/callGridColumnsSelect/CallGridColumnsSelect.vue";
import { StoreActionTypes } from "@/store/types";

@Component({
  components: {
    GridFilter,
    GridFooter,
    GridComponent,
    CallGridColumnsSelect,
  },
})
export default class CallsList extends Vue {
  public componentClass = "CallList";

  async mounted() {
    console.log(`mounted`);
    await this.$store.dispatch(StoreActionTypes.GET_SAVED_FILTERS);
    this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIMITS);
  }

  get getTotal(): number {
    return this.$store.getters.getCallsTotal;
  }

  get getTotalDuration(): number {
    return this.$store.getters.getCallsTotalDuration;
  }

  get getCurrentPage(): number {
    return this.$store.getters.getCallsListPage;
  }

  get perPage(): number {
    return this.$store.getters.getCallsListPerPage;
  }

  get savedFilters() {
    return this.$store.getters.getSavedFilters;
  }

  get projectSelectOptions() {
    return this.$store.getters.getCallsProjectsList;
  }

  async handlePageItemQuantitySelectChangeEvent(selected: string) {
    this.$store.dispatch(
      StoreActionTypes.SET_CALLS_LIST_PER_PAGE,
      parseInt(selected)
    );
    await this.$store.dispatch(StoreActionTypes.SET_CALLS_LIST);
  }

  async handlePaginatorChangeEvent(page: number) {
    this.$store.dispatch(StoreActionTypes.SET_CALLS_LIST_PAGE, page);
    await this.$store.dispatch(StoreActionTypes.SET_CALLS_LIST);
  }
}

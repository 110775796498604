
import Vue from "vue";
import Component from "vue-class-component";
import LoginForm from "@/components/form/LoginForm.vue";
import { i18n } from "@/i18n/index";

@Component({
  components: {
    LoginForm,
  },
})
export default class Auth extends Vue {
  private componentClass = "LoginPage";
  private showToast = false;

  private toastText = "";

  mounted() {
    this.checkQueryParams();
  }

  checkQueryParams() {
    if (this.$route.query.passChanged || this.$route.query.passReseted) {
      this.toastText = this.$route.query.passChanged
        ? (i18n.t("auth.passwordChanged") as string)
        : (i18n.t("auth.emailWithInstructions") as string);
      this.showToast = true;
      this.$router.replace({ query: null });
    }
  }
}

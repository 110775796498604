
import Vue from "vue";
import Component from "vue-class-component";
import Search from "@/components/ewa/search/SearchInput.vue";
import Row from "@/components/ewa/table/Row.vue";
import Table from "@/components/ewa/table/Table.vue";
import { StoreActionTypes } from "@/store/types";

@Component({
  components: {
    Table,
    Search,
    Row,
  },
})
export default class EwaHomePage extends Vue {
  async mounted() {
    /*this.$root.openDialog({
      component: {
        is: () => import('@/components/ewa/modals/Tariffs.vue'),
        _name: 'Tariffs',
      },
      settings: {
        position: 'center'
      }
    });*/
    if (this.userAuth.company) {
      await this.$store.dispatch(StoreActionTypes.GET_BALANCE);

      await this.$store.dispatch(StoreActionTypes.GET_USER_INSTANCES);
    }
  }
  get userAuth() {
    return this.$store.getters.getAuthentificationProfile;
  }
  get instanceList() {
    return this.$store.getters.getInstanceList;
  }
  get balance() {
    return this.$store.getters.getBalance;
  }
  openCreateDialog() {
    (this.$root as any).openDialog({
      component: {
        is: () => import("@/components/ewa/modals/Tariffs.vue"),
        _name: "Tariffs",
      },
      settings: {
        position: "center",
      },
    });
  }
}


import Vue from "vue";
import Component from "vue-class-component";
import changes from "./changelog.json";
import { getAppVersion } from "@/services/utils";

@Component({})
export default class Changelog extends Vue {
  private changeList = changes;
  get version() {
    return getAppVersion();
  }
}


import Vue from "vue";
import Component from "vue-class-component";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import FormInput from "@/components/lib/input/FormInput.vue";
import { StoreActionTypes } from "@/store/types";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import GridFilter from "@/components/grids/checkListsList/filter/GridFilter.vue";
import GridFooter from "@/components/grids/main/gridFooter/GridFooter.vue";
import GridComponent from "@/components/grids/checkListsList/list/GridComponent.vue";
import { isUserHasPermission } from "@/services/roles";
import { UserPermissions } from "@/services/roles/permissionsEnum";
import { isUserCanManageChecklists } from "@/services/roles/helpers";

@Component({
  computed: {
    UserPermissions() {
      return UserPermissions;
    },
  },
  methods: { isUserCanManageChecklists, isUserHasPermission },
  components: {
    GridFilter,
    GridFooter,
    GridComponent,
    ActionButton,
    FormInput,
    ModalComponent,
  },
})
export default class CheckListsList extends Vue {
  private componentClass = "CheckListsList";

  private modalShow = false;
  private checkListName = "";

  handleCreateNewCheckListBtnClick() {
    this.modalShow = !this.modalShow;
  }

  async handleModalOkBtnClick() {
    await this.createCheckList();
  }

  async createCheckList() {
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_CHECKLIST_CREATE,
      this.checkListName
    );
    const checkListId = this.$store.getters.getCheckListsSelectedCheckList.id;
    this.handleCreateNewCheckListBtnClick();
    this.$router.push({
      path: `check-lists/${checkListId}`,
      query: { isNew: "true" },
    });
  }

  checkListNameChange(text: string) {
    this.checkListName = text;
  }

  get modalOkBtnDisabled() {
    return this.disabled;
  }

  get disabled() {
    return this.$store.getters.isCheckListsDataLoading;
  }

  get getTotal(): number {
    return this.$store.getters.getCheckListsTotal;
  }

  get getCurrentPage(): number {
    return this.$store.getters.getCheckListsListPage;
  }

  get perPage(): number {
    return this.$store.getters.getCheckListsListPerPage;
  }

  async handlePageItemQuantitySelectChangeEvent(selected: string) {
    this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_LIST_PER_PAGE,
      parseInt(selected)
    );
    await this.$store.dispatch(StoreActionTypes.CHECKLISTS_LIST);
  }

  async handlePaginatorChangeEvent(page: number) {
    this.$store.dispatch(StoreActionTypes.CHECKLISTS_LIST_PAGE, page);
    await this.$store.dispatch(StoreActionTypes.CHECKLISTS_LIST);
  }
}

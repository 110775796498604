
import { isUserCanManageChecklists } from "@/services/roles/helpers";

declare const window: any;
import Vue from "vue";
import Component from "vue-class-component";
import GridFilter from "@/components/grids/categoryList/filter/GridFilter.vue";
import GridFooter from "@/components/grids/main/gridFooter/GridFooter.vue";
import GridComponent from "@/components/grids/categoryList/list/GridComponent.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import FormInput from "@/components/lib/input/FormInput.vue";
import { StoreActionTypes } from "@/store/types";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { IconDefinition, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

@Component({
  methods: { isUserCanManageChecklists },
  components: {
    GridFilter,
    GridFooter,
    GridComponent,
    ActionButton,
    FormInput,
    ModalComponent,
  },
})
export default class Categories extends Vue {
  private componentClass = "CategoriesList";
  private back: IconDefinition = faArrowLeft;

  private modalShow = false;
  private categoryName = "";

  handleCreateNewCategoryBtnClick() {
    this.modalShow = !this.modalShow;
  }

  async handleModalOkBtnClick() {
    await this.createCategory();
  }

  async createCategory() {
    if (this.categoryName.length < 5) {
      window.error(
        "Имя группы не может быть пустым или содержать менее 5 символов",
        "Ошибка создания группы"
      );
      return;
    }
    await this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_CATEGORY_CREATE,
      this.categoryName
    );
    const categoryId = this.$store.getters.getCategoriesSelectedCategory.id;
    this.handleCreateNewCategoryBtnClick();
    this.$router.push(`criterions-groups/${categoryId}`);
  }

  categoryNameChange(text: string) {
    this.categoryName = text;
  }

  get modalOkBtnDisabled() {
    return this.disabled;
  }

  get disabled() {
    return this.$store.getters.isCategoriesDataLoading;
  }

  get getTotal(): number {
    return this.$store.getters.getCategoriesTotal;
  }

  get getCurrentPage(): number {
    return this.$store.getters.getCategoriesListPage;
  }

  get perPage(): number {
    return this.$store.getters.getCategoriesListPerPage;
  }

  async handlePageItemQuantitySelectChangeEvent(selected: string) {
    this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_LIST_PER_PAGE,
      parseInt(selected)
    );
    await this.$store.dispatch(StoreActionTypes.SET_CATEGORIES_LIST);
  }

  async handlePaginatorChangeEvent(page: number) {
    this.$store.dispatch(StoreActionTypes.SET_CATEGORIES_LIST_PAGE, page);
    await this.$store.dispatch(StoreActionTypes.SET_CATEGORIES_LIST);
  }

  handleBackIconClick() {
    this.$router.push("/check-lists");
  }
}

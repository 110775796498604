
import Modal2Component from "@/components/modal/Modal2Component.vue";
import Vue from "vue";
import Component from "vue-class-component";
import {
  faArrowLeft,
  faPencilAlt,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import {
  CategoryModel,
  ChangeCategoryStatusModel,
  ColorTheme,
  CreateCriterionRequestModel,
  StatusType,
} from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import ConfirmationComponent from "@/components/modal/ConfirmationComponent.vue";
import CreateCriterionModalForm from "@/components/form/CreateCriterionModalForm.vue";
import { isUserCanManageChecklists } from "@/services/roles/helpers";

declare const window: any;

@Component({
  methods: { isUserCanManageChecklists },
  components: {
    Modal2Component,
    FormInput,
    ActionButton,
    ModalComponent,
    ConfirmationComponent,
    CreateCriterionModalForm,
  },
  watch: {
    "$store.getters.getCategoriesSelectedCategory": "setEditedCategoryName",
  },
})
export default class CategoryDetailsHeader extends Vue {
  private componentClass = "CategoryDetailsHeader";
  private back: IconDefinition = faArrowLeft;
  private edit: IconDefinition = faPencilAlt;
  private showChangeCategoryNameForm = false;
  private editedCategoryName = "";
  private changeStatusCategoryConfirmationShow = false;
  private deleteCategoryConfirmationShow = false;
  private showCreateCriterionForm = false;

  mounted() {
    this.setEditedCategoryName();
  }

  getCategoryStatus(): StatusType {
    return (this.$store.getters.getCategoriesSelectedCategory as CategoryModel)
      ? (this.$store.getters.getCategoriesSelectedCategory as CategoryModel)
          .status
      : null;
  }

  get categoryName() {
    return (this.$store.getters.getCategoriesSelectedCategory as CategoryModel)
      ? (this.$store.getters.getCategoriesSelectedCategory as CategoryModel)
          .name
      : "";
  }

  get statusDropdownTitle() {
    return this.getCategoryStatus() == "ACTIVE"
      ? this.$i18n.t("criterionsDetails.title.active")
      : this.$i18n.t("criterionsDetails.title.deactive");
  }

  get statusDropdownItemTitle() {
    return this.getCategoryStatus() == "ACTIVE"
      ? this.$i18n.t("criterionsDetails.item.deactivate")
      : this.$i18n.t("criterionsDetails.item.activate");
  }

  get statusDropdownItemVariant(): ColorTheme {
    return this.getCategoryStatus() == "ACTIVE" ? "success" : "warning";
  }

  get modalChangeCategoryNameFormDisabled() {
    return this.disabled || this.editedCategoryName.length < 3;
  }

  get disabled() {
    return this.$store.getters.isCategoriesDataLoading;
  }

  get criterionCreateDisabled() {
    return this.$store.getters.isCriterionsDataLoading;
  }

  handleEditCategoryNameBtnClick() {
    this.showChangeCategoryNameForm = !this.showChangeCategoryNameForm;
  }

  categoryNameChange(text: string) {
    this.editedCategoryName = text;
  }

  async handleModalChangeCategoryNameFormOkBtnClick() {
    if (this.editedCategoryName.length < 5) {
      window.error(
        "Имя группы не может быть пустым или содержать менее 5 символов",
        "Ошибка редактирования группы"
      );
      return;
    }
    await this.editCategoryName();
    this.handleEditCategoryNameBtnClick();
  }

  async editCategoryName() {
    await this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_CATEGORY_CHANGE,
      {
        name: this.editedCategoryName,
      }
    );
  }

  handleBackIconClick() {
    this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_SELECTED_CATEGORY_CLEAR
    );
    this.$router.push("/criterions-groups");
  }

  setEditedCategoryName() {
    this.editedCategoryName = (this.$store.getters
      .getCategoriesSelectedCategory as CategoryModel)
      ? (this.$store.getters.getCategoriesSelectedCategory as CategoryModel)
          .name
      : "";
  }

  handleChangeStatusItemClick() {
    this.changeStatusCategoryConfirmationShow = true;
  }

  async changeCategoryStatus() {
    const model: ChangeCategoryStatusModel = {
      id: (this.$store.getters.getCategoriesSelectedCategory as CategoryModel)
        .id,
      status: (
        this.$store.getters.getCategoriesSelectedCategory as CategoryModel
      ).status,
    };
    await this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_CATEGORY_CHANGE_STATUS,
      model
    );

    this.changeCategoryStatusCancel();
  }

  async handleCreateCriterionModalOkBtnClick() {
    const model: CreateCriterionRequestModel = (
      this.$refs.createNewCriterionModalForm as CreateCriterionModalForm
    ).getCreateCriterionRequestModel();

    try {
      await this.$store.dispatch(
        StoreActionTypes.SET_CRITERIONS_CRITERION_CREATE,
        {
          criterion: model,
          list: [],
        }
      );
      await this.$store.dispatch(
        StoreActionTypes.SET_CRITERIONS_LIST_BY_CATEGORY_ID
      );
      this.showCreateCriterionForm = false;
    } catch (e) {
      console.error(e);
    }
  }

  changeCategoryStatusCancel() {
    this.changeStatusCategoryConfirmationShow = false;
  }

  get setChangeStatusConfirmationOkBtnVariant() {
    return this.getOkBtnVariant(this.getCategoryStatus());
  }

  get getCategory() {
    return this.$store.getters.getCategoriesSelectedCategory as CategoryModel;
  }

  getOkBtnVariant(status: StatusType): ColorTheme {
    return status == "ACTIVE" ? "danger" : "success";
  }

  handleDeleteCategoryBtnClick() {
    this.deleteCategoryConfirmationShow = true;
  }

  deleteCategoryCancel() {
    this.deleteCategoryConfirmationShow = false;
  }

  async deleteCategory() {
    await this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_CATEGORY_DELETE,
      {
        ids: [
          (this.$store.getters.getCategoriesSelectedCategory as CategoryModel)
            ? (
                this.$store.getters
                  .getCategoriesSelectedCategory as CategoryModel
              ).id
            : null,
        ],
      }
    );

    this.deleteCategoryCancel();
    this.handleBackIconClick();
  }
}

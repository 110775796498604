
import Vue from "vue";
import Component from "vue-class-component";
import ResetPasswordForm from "@/components/form/ResetPasswordForm.vue";
import { StoreActionTypes } from "@/store/types";

@Component({
  components: {
    ResetPasswordForm,
  },
})
export default class ResetPassword extends Vue {
  mounted() {
    this.$store.dispatch(StoreActionTypes.SET_PASSWORD_HASH, "");
  }
}


import Vue from "vue";
import Component from "vue-class-component";
import SetPasswordForm from "@/components/form/SetPasswordForm.vue";
import { StoreActionTypes } from "@/store/types";

@Component({
  components: {
    SetPasswordForm,
  },
})
export default class SetPassword extends Vue {
  mounted() {
    this.checkQueryParams();
  }

  checkQueryParams() {
    if (this.$route.query.hash && this.$route.query.hash.length > 0) {
      this.$store.dispatch(
        StoreActionTypes.SET_PASSWORD_HASH,
        this.$route.query.hash
      );
    } else {
      this.$router.push("/login");
    }
  }
}

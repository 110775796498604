
import { isUserCanManageChecklists } from "@/services/roles/helpers";

declare const window: any;
import Vue from "vue";
import Component from "vue-class-component";
import GridFilter from "@/components/grids/criterionList/filter/GridFilter.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import GridFooter from "@/components/grids/main/gridFooter/GridFooter.vue";
import GridComponent from "@/components/grids/criterionList/list/GridComponent.vue";
import CreateCriterionModalForm from "@/components/form/CreateCriterionModalForm.vue";
import { CreateCriterionRequestModel } from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import { IconDefinition, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

@Component({
  methods: { isUserCanManageChecklists },
  components: {
    GridFilter,
    ActionButton,
    ModalComponent,
    GridFooter,
    GridComponent,
    CreateCriterionModalForm,
  },
})
export default class Criterion extends Vue {
  private componentClass = "CriterionPage";
  private showCreateNewCriterionForm = false;
  private back: IconDefinition = faArrowLeft;

  handleCreateNewCriterionBtnClick() {
    this.showCreateNewCriterionForm = !this.showCreateNewCriterionForm;
  }

  async handleCreateNewCriterionModalOkBtnClick() {
    const model: CreateCriterionRequestModel = (
      this.$refs.createNewCriterionModalForm as CreateCriterionModalForm
    ).getCreateCriterionRequestModel();

    const optionsNotFilled = model.options.find(
      (v) => v.name.length === 0 || v.nominalWeight === null
    );
    if (
      model.name.length === 0 ||
      model.categoryId === 0 ||
      model.options.length === 0 ||
      optionsNotFilled
    ) {
      return window.error(
        "Проверьте правильность заполненных полей",
        "Ошибка создания критерия"
      );
    }
    await this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_CRITERION_CREATE,
      model
    );
    await this.$store.dispatch(StoreActionTypes.SET_CRITERIONS_LIST);
    this.handleCreateNewCriterionBtnClick();
  }

  get createNewCriterionFormDisabled() {
    return this.$store.getters.isCriterionsDataLoading;
  }

  get getTotal(): number {
    return this.$store.getters.getCriterionsTotal;
  }

  get getCurrentPage(): number {
    return this.$store.getters.getCriterionsListPage;
  }

  get perPage(): number {
    return this.$store.getters.getCriterionsListPerPage;
  }

  async handlePageItemQuantitySelectChangeEvent(selected: string) {
    this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_LIST_PER_PAGE,
      parseInt(selected)
    );
    await this.$store.dispatch(StoreActionTypes.SET_CRITERIONS_LIST);
  }

  async handlePaginatorChangeEvent(page: number) {
    this.$store.dispatch(StoreActionTypes.SET_CRITERIONS_LIST_PAGE, page);
    await this.$store.dispatch(StoreActionTypes.SET_CRITERIONS_LIST);
  }

  handleBackIconClick() {
    this.$router.push("/check-lists");
  }
}

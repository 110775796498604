
import Vue, {PropType} from "vue";
import Component from "vue-class-component";
import CallDetailsHeader from "@/components/callDetails/header/CallDetailsHeader.vue";
import CallDetailsMainInfo from "@/components/callDetails/main/CallDetailsMainInfo.vue";
import PlayerComponent from "@/components/lib/player/PlayerComponent.vue";
import CallDetailsComments from "@/components/callDetails/comments/CallDetailsComments.vue";
import CallDetailsRatingHeader from "@/components/callDetails/header/CallDetailsRatingHeader.vue";
import CallRating from "@/components/callDetails/rating/CallRating.vue";
import CallCheckList from "@/components/callDetails/checklists/CallCheckList.vue";
import {StoreActionTypes, StoreMutationTypes} from "@/store/types";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import AudioPlayer from "@/components/player2/AudioPlayer.vue";
import {getStore} from "@/store/getStore";
import {DetailedCallEntityModel} from "@/services/types/entities/entities";
import GlobalAudioPlayerControls from "@/components/player2/GlobalAudioPlayerControls.vue";
import {usePlayerStore} from "@/pinia/player";
import {isUserHasPermission} from "@/services/roles";
import {UserPermissions} from "@/services/roles/permissionsEnum";
import AudioPlaylist from "@/components/player2/AudioPlaylist.vue";
import ImageGallery from "@/components/ui/ImageGallery.vue";
import VideoGallery from "@/components/ui/VideoGallery.vue";
import AddAttachments from "@/components/callDetails/AddAttachments.vue";
import CallSttResults from "@/features/call2text/CallSttResults.vue";
import {isRecognitionDone} from "@/features/call2text/helpers";
import CallSttState from "@/features/call2text/CallSttState.vue";

const ICallsDetailsProps = Vue.extend({
  props: {
    closeListener: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
});

@Component({
  computed: {
    UserPermissions() {
      return UserPermissions;
    },
  },
  methods: {isUserHasPermission},
  components: {
    CallSttState,
    CallSttResults,
    AddAttachments,
    VideoGallery,
    ImageGallery,
    AudioPlaylist,
    GlobalAudioPlayerControls,
    AudioPlayer,
    CallDetailsHeader,
    PlayerComponent,
    CallDetailsMainInfo,
    CallDetailsComments,
    CallDetailsRatingHeader,
    CallRating,
    CallCheckList,
    ActionButton,
  },
  watch: {
    "$store.getters.getSelectedCallEstimatedCheckList":
        "handleSelectedCallEstimatedCheckListChange",
  },
})
export default class CallsDetails extends ICallsDetailsProps {
  public componentClass = "CallsDetails";

  closeIcon = faClose;

  public callRatingWrapperHeight = "100%";
  public checkListLoaded = true;

  public callEditing = false;
  public selectedManagerId = null;

  get selectedEntity() {
    return getStore().getters.getSelectedCall
  }

  get entityId() {
    return getStore().getters.getSelectedCall.id
  }

  get attachments() {
    return (
        getStore().getters.getSelectedCall as unknown as DetailedCallEntityModel
    ).attachments;
  }

  get audioAttachments() {
    const regexp = /audio|octet-stream/
    return this.attachments
        .filter((a) => regexp.test(a.metadata.mediaType))
        .map((a) => ({
          ...a,
          storageLink: `${a.storageLink}/${a.id}.mp3`,
        }));
  }

  get imageAttachments() {
    const regexp = /image/
    return this.attachments.filter((a) => regexp.test(a.metadata.mediaType))
  }

  get imageAttachmentsSources() {
    return this.imageAttachments.map((a) => a.storageLink);
  }

  get videoAttachments() {
    const regexp = /video/
    return this.attachments.filter((a) => regexp.test(a.metadata.mediaType))
  }

  get videoAttachmentsItems() {
    return this.videoAttachments.map((i) => ({
      name: i.metadata['filename']?.toString() || i.id.toString(),
      src: `${i.storageLink}/video.mp4`
    }));
  }

  get showRecognitionResults(): boolean {
    return this.audioAttachments.some(i => isRecognitionDone(i.sttState))
  }

  get canBeEdited(): boolean {
    return !!this.$store.getters.getAuthentificationProfile.company;
  }

  get callIsEdited(): boolean {
    return (
        this.$store.getters.getSelectedCallAllCriterionsChecked &&
        !this.$store.getters.getSelectedCallRated
    );
  }

  get userCanRate(): boolean {
    return (
        this.$store.getters.getSelectedCallAllCriterionsChecked &&
        isUserHasPermission(UserPermissions.ESTIMATE_COMPANY_ENTITIES)
    );
  }

  get dataLoading(): boolean {
    return this.$store.getters.isSelectedCallDataLoading;
  }

  get checkListId(): number {
    return this.$store.getters.getSelectedCallCheckListId;
  }

  get isOwner(): boolean {
    return this.$store.getters.getAuthentificationProfile.isOwner;
  }

  get callRated(): boolean {
    return (
        this.$store.getters.getSelectedCallRated ||
        !!this.getSelectedCallEstimatedCheckList
    );
  }

  get isRateButtonDisabled(): boolean {
    if (this.isOwner) {
      return true;
    }

    if (!this.callRated) {
      return true;
    }

    if (!this.userCanRate) {
      return true;
    }

    return false;
  }

  get getSelectedCallEstimatedCheckList() {
    return this.$store.getters.getSelectedCallEstimatedCheckList;
  }

  get selectedAuditorId() {
    return this.$store.getters.getSelectedCallSelectedAuditorId;
  }

  created() {
    window.addEventListener("keyup", this.playerKeyboardControl);
  }

  mounted() {
    // this.setHeight(200);
    usePlayerStore().resetLastPlayed();
    this.$store.commit(
        StoreMutationTypes.SET_TOP_COLLAPSE_OPENED,
        this.callNotRated
    );
  }

  destroyed() {
    window.removeEventListener("keyup", this.playerKeyboardControl);
  }

  handleCloseClick() {
    this.$emit("close-btn-click");
    this.closeListener();
  }

  private playerKeyboardControl(event: KeyboardEvent) {
    switch (event.code) {
      case "ShiftRight":
        this.$store.dispatch(
            StoreActionTypes.SET_PLAYER_PLAY,
            !this.$store.getters.isPlayerPlay
        );
        break;
      default:
        break;
    }
  }

  async reEstimate() {
    if (this.checkListId != null) {
      this.selectedManagerId = this.selectedAuditorId;
      this.$store.commit(
          StoreMutationTypes.SET_SELECTED_CALL_RATED_CLEAR,
          true
      );
      this.$store.commit(StoreMutationTypes.SET_SELECTED_CALL_RATED, false);
      this.$store.commit(StoreMutationTypes.SET_PLAYER_IS_PLAYED, true);
      await this.$store.dispatch(
          StoreActionTypes.SET_SELECTED_CALL_CATEGORIES,
          this.checkListId
      );
      this.callEditing = true;
    }
  }

  setHeight(timeout: number) {
    setTimeout(() => {
      const heightAll = (this.$refs.callRatingColumnRight as HTMLBaseElement)
          .clientHeight;
      const heightTop = (this.$refs.callRatingHeaderWrapper as HTMLBaseElement)
          .clientHeight;

      this.callRatingWrapperHeight = `${heightAll - heightTop - 20}px`;
    }, timeout);
  }

  handleSelectedCallEstimatedCheckListChange() {
    // this.setHeight(200)
  }

  get callEstimationBtnDisabled(): boolean {
    if (this.$store.getters.getPlayerIsPlayed && this.allCriterionsChecked) {
      return this.$store.getters.isSelectedCallDataLoading;
    }

    return true;
  }

  async handleCallEstimationBtnClick() {
    if (this.callEditing) {
      await this.$store.dispatch(
          StoreActionTypes.SET_SELECTED_CALL_EDIT_ESTIMATION,
          this.selectedManagerId
      );
      this.callEditing = false;
    } else {
      await this.$store.dispatch(
          StoreActionTypes.SET_SELECTED_CALL_SET_ESTIMATION
      );
    }
  }

  loadCheckList() {
    this.checkListLoaded = false;
    setTimeout(() => (this.checkListLoaded = true), 200);
  }

  get allCriterionsChecked(): boolean {
    return this.$store.getters.getSelectedCallAllCriterionsChecked;
  }

  get callNotRated(): boolean {
    return !this.$store.getters.getSelectedCallRated;
  }

  get projectLimits(): any {
    return this.$store.getters.getProjectsLimits;
  }

  get getCollapse() {
    return !this.$store.getters.getTopCollapseOpened;
  }

  get isCallEstimationBtnBlocked(): boolean {
    if (
        !this.projectLimits ||
        !this.projectLimits.callsEstimations ||
        (!this.projectLimits.integrations &&
            !this.projectLimits.callsEstimations.max &&
            !this.projectLimits.integrations.max)
    ) {
      return false;
    }
    if (
        this.projectLimits.callsEstimations.totalActive <
        this.projectLimits.callsEstimations.max &&
        this.projectLimits.integrations.totalActive <
        this.projectLimits.integrations.max
    ) {
      return false;
    }
    return true;
  }
}
